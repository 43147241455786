@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
.raleway-sans-serif {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;  
    font-style: normal;
}
body{
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    margin: 0;
    padding:0;
}
.portfolio-container{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}
.section{
    width: 100%;
    padding: 30px;
    padding-top: 50px;
}

.section-title{
    margin: 30px 0px;
    color: rgb(0, 132, 255);
}

/* navbar  */
.navbar{
    padding: 0;
    display: flex;
    justify-content: right;
    align-items: center;
    background-color: rgb(0, 132, 255);   
    position: fixed;
    top: 0;
    width: 100%;
    height: max-content;
    z-index: 1;
}
.tabs{
    display: flex;
}
.tab-item a{
    text-decoration: none;
    padding: 15px;
    color: white;
    font-weight: normal;
}

.tab-item{
    padding: 13px;
}
.tab-item:hover{
    background-color: rgba(0, 0, 0, 0.173);
}

.hamburger-icon{
    display: none;
    text-align: right;
    width: 100%;
    padding: 10px;
}

/* home section */

.home{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: rgb(211, 234, 251); */
    
    
}

.home-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    /* border: 1px solid black; */
    height: 700px;
    text-align: center;
}
div.profile-info > h1{
    font-size: 4rem;
    transition: 0.4s;
}
div.profile-info > h1 > span{
    color: rgb(0, 132, 255);
}

div.profile-info > p{
    font-size: 1.5rem;
        transition: 0.4s;
}

/* about section */
.about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.about-container{
    width: 80%;
    /* border: 1px solid red; */
    display: flex;
    height: 500px;
    align-items: center;
}
.faq-container{
    margin-left: 50px;
    width: 100%;
}

/* skill section */
.skills{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.skill-container{
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px 30px;
}

/* experience section */
.experience{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.experience-container{
    width: 80%;
}

.experience-card{
    display: flex;
    border-radius: 10px;
    background-color: #EEF5FF;
    
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.experience-card>div.card-child-1{
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center
}

.experience-card>div.card-child-1>img{
    margin: 5px;
    height: 70px;
    width: 80px;
    mix-blend-mode:multiply
}

.experience-card:hover{
    background-color: #86b7f653;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.experience-card>div.card-child-2>div.role-description{
    text-align: justify;
}

/* project section */
.projects{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px 30px;
    width: 80%;
}
.project-card{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.project-card:hover{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

/* contact section */
.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contact-container{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px 0px;
    margin-bottom: 100px;
    /* border: 1px solid red; */
    /* background-color: red; */
}

.contact-detail-container{
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid blue; */
}
.contact-form-container{
    /* border: 1px solid blue; */
}
.contact-detail{
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-detail-icon{
}
.contact-detail-body{
    width: 300px;
    margin: 20px 20px;
}
.contact-detail-title, .contact-detail-description{
    margin: 0px;
}

.footer{
    /* display: flex;
    align-items: center;
    justify-content: center; */
    text-align: center;
    background-color: black;
}

.footer > a > img{
    /* border: 1px solid white; */
    margin: 5px 10px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7424%) hue-rotate(149deg) brightness(120%) contrast(117%);
}

/* social links section */
.social{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.social-link-container{
    /* margin-top: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
}


@media only screen and (max-width:1400px){
    .skill-container{
        width: 100%;
    }
}

@media only screen and (max-width:1200px){
    
    .about-container{
        height: 100%;
        width: 90%;
        flex-direction: column;
    }
    .faq-container{
        margin-top: 50px;
        margin-left: 0;
    }
    div.faq>span{
        /* margin-left: 0px; */
        margin-right: 100%;
    }
    .project-container{
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width:900px){

    .skill-container{
        grid-template-columns: 1fr;
    }
    .navbar{
        justify-content: center;
    }
    .home-container{  
        grid-template-columns: 1fr;
    } 
    .contact-container{
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width:750px){
    div.profile-info > h1{
        font-size: 2rem;
        transition: 0.4s;
    }
    div.profile-info > p{
        font-size: 1rem;
        transition: 0.4s;
    }
    .section{
        padding: 10px;
        padding-top: 50px;
    }
    .navbar{
        justify-content: center;
    }
    .hamburger-icon{
        display:inline;
    }
    .hamburger-icon > img{
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(3deg) brightness(107%) contrast(101%);
    }
    .tabs{
        width: 100%;
        flex-direction: column;
        align-items: left;
    }
    .experience-container{
        width: 100%;
    }
    .experience-card{
        flex-direction: column;
    }
    .experience-card>div.card-child-1{
       flex-direction: row;
       justify-content: center;
       width: 100%;
   }
    .experience-card>div.card-child-1>div{
        display: none;
    }
    
    .hide-nav-tabs{
        display: none;
    }
    .navbar{
        flex-direction: column;
    }
    .contact-detail{
        width: 90%;
    }
    .contact-detail-container{
        width: 90%;
        place-self: center;
    }
    .contact-form-container{
        width: 90%;
        place-self: center;
    }
    .project-container{
        width: 90%;
        grid-template-columns: 1fr;
    }
}